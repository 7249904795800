import React from 'react';
import { Link } from 'react-router-dom';
import data from '../data';

export default function Start() {
  const courses = data;

  return (
    <>

      <div className="p-3">
        <div className="my-4">
          <h1>Dango Trainingszentrum</h1>
        </div>
          {courses.filter(c=>c.hidden!==true).map((course) => (
        <div  key={course.id}  className="border border-2 p-2 py-3 rounded border-warning bg-warning bg-opacity-10 mb-4">
            <div className="p-1">
              <h3>{course.title}</h3>
              <ol className=" mb-2" style={{ paddingRight: '32px' }}>
                {course.sections.map((section, i) => (
                  <li
                    key={section.title}
                    className="list-group-item text-start">
                    {i + 1}. {section.title}
                  </li>
                ))}
              </ol>

              <Link to={`/train/course/${course.id}`} style={{ textDecoration: "none" }}>
                <div className="d-grid">
                  <button className="btn btn-lg btn-dark">
                    Schulung starten
                  </button>
                </div>
              </Link>
            </div>
        </div>
          ))}
      </div>
    </>
  );
}

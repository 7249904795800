import React from 'react';

export default function AttentionGetter({ handler }) {
  return (
    <div
      className=" position-absolute h-100 w-100 p-4 bg-warning bg-opacity-50 d-flex flex-column justify-content-center align-items-center"
      style={{ left: '0', top: '0' }}>
      <h1>Bevor wir loslegen..</h1>
      <p className="fs-5   ">
        Bitte suche dir einen ruhigen Platz aus, an dem du nicht abgelenkt wirst. Die Schulung dauert dann auch nicht länger als 15 Minuten.
      </p>
      <button className="btn btn-lg btn-dark" onClick={handler}>
        OK
      </button>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import data from "../data"

const CourseList = () => {
    return (
        <div className='h-100'
            style={{ maxWidth: "600px", margin: "auto", textAlign:"left", padding:"20px" }}>
            {data.map(c => <li key={c.id}><Link to={`/train/course/${c.id}`}>{c.title}</Link> <input type="text" disabled value={`https://training.paywithdango.com/train/course/${c.id}`} /></li>) }
           
        </div>
    );
};

export default CourseList;
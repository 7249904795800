import React from 'react';

const SectionCoverPage = ({ section, sectionNumber, goToNextPage, hasNextSection }) => {
    return (
        <div className=" p-2 pb-5 px-3 h-100 w-100 d-flex flex-column bg-dark text-white justify-content-center m-0" style={{ left: 0, top: 0, position: "absolute" }}>
            <div>
                <h6>{sectionNumber === 0 ? "Los geht's mit Kapitel 1:" : !hasNextSection ? "Und nun das letzte Kapitel" : `Weiter geht's mit Kapitel ${sectionNumber + 1}`}</h6>
                <h3>{section.title}</h3>
            </div>
            <div className="pt-2">
                <button className="btn btn-lg btn-light" onClick={goToNextPage}>Start</button>
            </div>
        </div>
    )
};

export default SectionCoverPage;
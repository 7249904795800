import React from 'react';

export default function CourseOverview({ handler, course }) {
  return (
    <div
      className=" position-absolute text-white h-100 w-100 p-4 bg-black bg-opacity-100 d-flex flex-column justify-content-center align-items-center"
      style={{ left: '0', top: '0' }}>
      <h1>Überblick</h1>
      <p className="fs-5   ">
      <h3>{course.title}</h3>
              <ol className=" mb-2" style={{ paddingRight: '32px' }}>
                {course.sections.map((section, i) => (
                  <li
                    key={section.title}
                    className="list-group-item text-start">
                    {i + 1}. {section.title}
                  </li>
                ))}
              </ol>
      </p>
      <button className="btn btn-lg btn-dark" onClick={handler}>
        Start
      </button>
    </div>
  );
}

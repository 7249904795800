import React, { useEffect } from 'react';
import Page from '../components/Page';
import ProgressBar from '../components/ProgressBar';
import SectionCoverPage from './SectionCoverPage';


export default function Section({
  section,
  hasNextSection,
  goToNextSection,
  goToNextPage,
  hasPrevSection,
  goToPrevSection,
  goToPrevPage,
  page,
  sectionNumber,
  env,
  cid
}) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [section]);



  const pages = section.pages;

  const hasNextPage = pages.length - 1 > page;
  const hasPrevPage = page > 0


  if (page === null) {
    return <SectionCoverPage section={section} sectionNumber={sectionNumber} goToNextPage={goToNextPage} hasNextSection={hasNextSection} />

  }

  return (
    <div className="">
      <div className='p-2 py-2 pt-3 bg-light bg-gradient border-bottom' style={{}}>
        <h5>{section.title}</h5>
        <ProgressBar pages={pages.length} currentPage={page} />
      </div>

      <Page
        page={pages[page]}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        goToNextPage={goToNextPage}
        goToPrevPage={goToPrevPage}
        hasNextSection={hasNextSection}
        goToNextSection={goToNextSection}
        hasPrevSection={hasPrevSection}
        goToPrevSection={goToPrevSection}
        env={env}
        cid={cid}
      />
    </div>
  );
}

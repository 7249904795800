import React, { useState } from 'react';


const Img = ({ src, alt, className, loader }) => {
    const [loading, setLoading] = useState(true)



    const onloadimg = () => {
        setLoading(false)
    }


    return <div className="mb-2" style={{ marginLeft: "-2px", marginRight: "-2px", width: "100%", border: "1px solid #cccccc", padding: "2px" }}>{loading && <>{loader}</>}<img src={src} alt={alt} className={className} onLoad={onloadimg} /></div>
}

export default Img
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Start from '../components/Start';
import Course from '../components/Course';
import CourseDone from '../components/CourseDone';

const Training = () => {
    return (
        <div className='h-100'
            style={{ maxWidth: "600px", margin: "auto" }}>
            <Routes>
                <Route path="/course/:courseId" element={<Course />} />

                <Route path="/course_done" element={<CourseDone />} />
                <Route path="/course_done/:courseId" element={<CourseDone />} />
                <Route path="/" element={<Start />} />

            </Routes>
        </div>
    );
};

export default Training;

const login_dashboard_hig = [
  {
    content: [
      { bold_paragraph: "Zum Einloggen ins Dashboard, folge bitte dieser Anleitung mit jeweils beiden Email-Adressen:" },
      { paragraph: "1. Öffne die folgende Webseite:" },
      { link: "https://dashboard.paywithdango.app/" },
      { paragraph: "2. Klicke auf \"Password forgotten\" und trage die Email-Adresse bl.standort@... ein." },
      { image: "https://dineup.imgix.net/Schulung/PW%20Reset.gif" },
      { paragraph: "3. Du erhältst eine Email und klickst auf den dort enthaltenen Link." },
      { paragraph: "4. Lege dein Passwort fest und logge dich ein." },
      { paragraph: "5. Lege das Dashboard als ⭐Favorit in deinem Browser an." }
      , { paragraph: "6. Mach das selbe noch Mal für die standort@Email-Adresse und speichere das Dashboard als Favorit auf eurem Bar/Theken-Computer" }
    ]
  }
]
const login_dashboard_allg = [
  {
    content: [
      { bold_paragraph: "Zum Einloggen ins Dashboard, folge bitte dieser Anleitung:" },
      { paragraph: "1. Öffne die folgende Webseite:" },
      { link: "https://dashboard.paywithdango.app/" },
      { paragraph: "2. Klicke auf \"Passwort vergessen\" und trage die Email-Adresse auf die wir dein Konto angelegt haben." },
      { image: "https://dineup.imgix.net/Schulung/PW%20Reset.gif" },
      { paragraph: "3. Du erhältst eine Email und klickst auf den dort enthaltenen Link." },
      { paragraph: "4. Nun bestimmst du dein Passwort und kannst dich anschließend von überall damit einloggen." },
      { paragraph: "5. Tipp: Lege das Dashboard als ⭐ Favorit in deinem Browser an." }
       
    ]
  }
]
const einfuerhung_dango_dashboard = [
  {
    title: "Einführung ins dango-Dashboard",
    pages: [{
      content: [
        { bold_paragraph: "Das zu Beginn erwähnte dango-Dashboard ermöglicht euch einige Vorteile: " },
        { paragraph: "- Live-Ansicht aller Bezahlungen über dango" },
        { paragraph: "- Erstellen eines Tagesberichts" },
        { paragraph: "- Erstellen eines Detailberichts " },
        { paragraph: "- Auswerten von Kunden-Feedback" }]
    },
    {
      content: [{ bold_paragraph: "Live-Ansicht" },
      { paragraph: "Im Dashboard werden in der Live-Ansicht alle Bezahlungen der letzten 8 Stunden über dango angezeigt. " },
      { image: "https://dineup.imgix.net/dashboard/Schulung.png" },
      { paragraph: "Für dein Personal ist diese Ansicht im Tagesgeschäft nicht unbedingt notwendig, weil bezahlte Tische automatisch über den Orderman rausgelassen werden. " },
      { paragraph: "Trotzdem schadet es nicht das Dashboard auch im normalen Tagesgeschäft zu öffnen, wenn ihr einen einen PC an eurer Bar oder Theke habt." }]
    },
    {
      content: [{ bold_paragraph: "Live-Ansicht" },
      { paragraph: "Wenn eine Zahlung in der Live-Ansicht angezeigt wird, ist der angezeigte Betrag zu 100 % bei uns eingegangen. Das heißt aber auch: Wenn eine Zahlung dort nicht auftaucht, dann hat der Gast auch nicht über dango bezahlt." },
      { image: "https://dineup.imgix.net/dashboard/Schulung.png" },
      { paragraph: "Grün bedeutet, dass der Warenkorb vollständig bezahlt wurde. " },
      { paragraph: "Gelb bedeutet, dass der Warenkorb nur teilweise bezahlt wurde. Der Tisch wird dann bei deinem Personal auf dem Orderman noch nicht rausgelassen. " },
      { bold_paragraph: "In ganz seltenen Fällen geht eine Bezahlung durch, wird aber bei deinem Personal im Orderman nicht als bezahlt angezeigt. " },
      { paragraph: "In diesem Fall wird die Live-Ansicht im Dashboard notwendig. Im Dashboard ist diese Zahlung, die zwar eingegangen ist, aber nicht an euren Orderman kommuniziert wurde, rot statt grün. " },
      { paragraph: "Dein Team muss dann das Trinkgeld manuell eintragen und den Tisch manuell über dango rauslassen. " }]
    },
    {
      content: [{ bold_paragraph: "Tagesbericht" },
      { paragraph: "Im Tagesbericht findest du eine Auflistung aller Zahlungen und Kennzahlen des aktuellen Tages." },
      { paragraph: "Hier kannst du auch den Tagesbericht von allen Zahlungen über dango auf Papier ausdrucken und mit dem Z-Bericht eurer Kasse abgleichen." },
      { image: "https://dineup.imgix.net/Schulung/Tagesansicht.png" },
      { bold_paragraph: "Egal, welche Zahlart die Gäste mit dango nutzen, du hast am Ende des Tages nur eine einzige Zahl, die du mit deinem Z-Bericht in Amadeus abgleichen musst." },
      { paragraph: "Je mehr Gäste also über dango bezahlen, umso schneller bist du mit dem Tagesabschluss fertig und kannst in den Feierabend." },
      { image: "https://dineup.imgix.net/Schulung/Kassenbericht.png" },
      { paragraph: "💡Übrigens: Das Format des Tagesberichts aus dango, könnt ihr ganz einfach im Dashboard unter “Einstellungen” ändern - entweder ihr nutzt das Format A4 oder aber das Bon-Format, wenn ihr den Bericht über euren Bon-Drucker ausgeben lassen möchtet." }]
    },
    {
      content: [{ bold_paragraph: "Detailbericht" },
      { paragraph: "Der Detailbericht ähnelt dem Tagesbericht sehr, ist jedoch nicht für einen einzigen Tag, sondern für einen definierten Zeitraum (z.B. für einen Monat)." },
      { image: "https://dineup.imgix.net/Schulung/Detailbericht.png" },

      ]
    },
    {
      content: [{ bold_paragraph: "Kunden-Feedback" },
      { paragraph: "Wir fragen jeden deiner Gäste nach der Bezahlung, wie ihm der Besuch bei euch gefallen hat." },
      { paragraph: "Wenn sie möchten, haben deine Gäste hier die Möglichkeit eine Sternebewertung abzugeben (1 bis 5 Sterne ⭐)." },
      { paragraph: "Bei 5 Sternen werden die Gäste mit einem Klick zu Google Review weitergeleitet und bei weniger als 5 Sternen, werden sie zu Lob und Tadel weitergeleitet. " },
      { bold_paragraph: "Die Sternebewertungen ⭐ deiner Gäste findest du im Dashboard unter Feedback." },
      { image: "https://dineup.imgix.net/Schulung/Kunden-Feedback.png" },
      { paragraph: "Dort siehst du eine Auflistung aller Bewertungen, die Zuordnung zu der entsprechenden Bestellung und, ob der Gast tatsächlich auf Lob oder Tadel bzw. Google Review geklickt hat." }]
    }]
  }
]

const qrcode_mapping_section = [
  {
    title: "QR-Codes kleben und Tischnummer zuweisen",
    pages: [
      {
        content: [
          { bold_paragraph: "Du hast von uns ein Päckchen mit den QR-Codes für deine Tische enthalten." },
          { bold_paragraph: "Damit am Tag, an dem dango bei euch live geht, alles glatt läuft, bereite bitte folgendes vor:" },
          { paragraph: "1. ❌ Entferne alte QR-Codes von deinen Tischen (1 Tag vor dem Go-Live-Termin). " },
          { paragraph: "2. 🆕 Klebe die neuen QR-Codes auf die Tische (am Tag des Go-Live-Termins). " },
          { paragraph: "3. 📱 Scanne alle QR-Codes nacheinander mit unserer Mapping-App." },
          { bold_paragraph: "Wie die Mapping-App funktioniert, erfährst du im nächsten Kapitel." },
          { bold_paragraph: "..." },
          {bold_paragraph: "Falls ihr Aufkleber als QR-Codes benutzt:"},
          { paragraph: "💡 Noch ein kleiner Tipp am Rande fürs Aufkleben:" },
          { paragraph: "Gehe beim Aufkleben so genauso vor, wie wenn du dein Handy mit einer Schutzfolie beklebst:" },
          { paragraph: "Staub entfernen ▶️ Langsam von einer Seite zur anderen Seite kleben ▶️ Vorsichtig Luftbläschen rausdrücken" }
        ]
      },
      {
        content: [
          { bold_paragraph: "So kommst du zur Mapping-App:" },

          { paragraph: "1. Dashboard auf deinem Handy öffnen" },
          { link: "https://dashboard.paywithdango.app" },
          { paragraph: "2. Klick auf “Einstellungen”" },
          { image: "https://dineup.imgix.net/training/dashboardmenu.png" },
          { paragraph: "3. Klick auf “QR Codes“ und dann auf “QR Codes zuweisen”" },
          { paragraph: "Die App wird um Zugriff auf deine Kamera fragen um die QR-Codes scannen zu können." }
        ]
      },
      {
        content: [
          { bold_paragraph: "Mit der Mapping-App verknüpfst du die einzelnen QR-Codes in nur wenigen Minuten mit deinen Tischen." },
          { paragraph: "1. Öffne die Mapping-App und trage einmalig deinen Restaurantnamen ein." },
          { paragraph: "2. Scanne mit der Kamera den QR-Code auf dem Tisch." },
          { image: "https://dineup.imgix.net/Schulung/Mapping%202.png" },
          { paragraph: "3. Weise dem QR-Code die entsprechende Tischnummer zu." },
          { image: "https://dineup.imgix.net/Schulung/Mapping%201.png" },
          { paragraph: "4. Wiederhole das Scannen und Benennen für alle Tische deines Restaurants." },
          { paragraph: "5. Wenn du fertig bist schickst du uns die Tischliste zu und wir machen den Rest." },
        ]
      },
      {
        content: [
          { bold_paragraph: "💡 Du wirst sicher feststellen, dass du mehr QR-Codes von uns erhalten hast, als du Tische im Restaurant hast. 💡" },
          { bold_paragraph: "Dafür gibt es einen guten Grund:" },
          { paragraph: "Bewahre die restlichen QR-Codes gut auf, denn möglicherweise musst du von Zeit zu Zeit einmal einen QR-Code auf dem Tisch austauschen." },
          { paragraph: "Entferne dann den alten QR-Code, bring einfach einen Neuen an, scanne den neuen QR-Code mit der Mapping-App und unser Team erfährt dann sofort per Mail von dem QR-Code-Austausch." }
        ]
      }
    ]
  }]
const section1 = (theme = "hig") => ({
  title: 'Wie funktioniert pay with dango?',
  pages: [
    {
      content: [
        {
          bold_paragraph: `
                dango ist eine ganz neue Art zu Bezahlen, die speziell für die Gastronomie entwickelt wurde. 
                Dango entlastet das Servicepersonal und macht den Bezahlvorgang für alle Beteiligten einfacher, schneller und komfortabler. 

                `,
        },
        {
          image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}qrcodeontable.png`,
        },

        {
          paragraph:
            'Übrigens: Ihr seid deutschlandweit eines der ersten Restaurants, die diese Lösung anbieten. 😎',
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `
                Im folgenden Video siehst du den Bezahlvorgang aus Sicht eines Gastes.
                `,
        },
        { youtube: 'AzMVq1UQceM' },
        {
          paragraph: `Als nächstes gehen wir das Ganze mal Schritt für Schritt gemeinsam durch.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wenn der Gast fertig gegessen hat und bezahlen möchte, scannt er 
          den QR-Code auf seinem Tisch. Jeder Tisch hat seinen eigenen QR-Code, wo auch die 
          jeweilige Tischnummer kodiert ist.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step1.${theme==="ce"?"png":"jpg"}` },

        {
          paragraph: `Damit dango also funktioniert, ist es wichtig, dass ihr 
        immer auf die richtige Tischnummer bonniert.`,
        },
        {
          paragraph: `
                Der Gast muss normalerweise keine QR-Code Scanner App herunterladen. Einfach nur die 
                normale Handy-Kamera öffnen und auf den QR-Code richten.
                `,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Nach dem Scannen öffnet sich im Browser die Startseite. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step4.${theme==="ce"?"png":"jpeg"}` },
        {
          paragraph: `Von hier können Gäste nicht nur über dango bezahlen, sondern auch die Speisekarte einsehen`,
        },
        {
          bold_paragraph: `Bei Klick auf Speisekarte sieht man die digitale Speisekarte mit zusätzlichen Infos zu Allergenen und Inhalttstoffen. Man kann darüber allerdings NICHT bestellen. Die Bestellaufnahme läuft weiterhin über dich.`,
        },
        {
          paragraph: `Bei Klick auf "Jetzt bezahlen" wird die offene Tisch-Rechnung aus eurer Kasse geladen.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Gäste sehen in der Übersicht alles was auf ihrem Tisch bonniert ist. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step5.${theme==="ce"?"png":"jpeg"}` },
        {
          paragraph: `Man kann zusammen oder getrennt bezahlen. Bei getrennter Zahlung kann auch ein Gast seinen Teil über dango zahlen und ein anderer bar oder mit Karte.`,
        },
        {
          paragraph: `Für das Trinkgeld machen wir vorberechnete Vorschläge. Gäste müssen also nichts ausrechnen, sondern klicken einfach auf die gewünschte Trinkgeldhöhe.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `So sieht es aus, wenn ein Gast auf "Rechnung aufteilen" klickt.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step6.${theme==="ce"?"png":"jpeg"}` },

        {
          paragraph: `Hier kann der Gast nun seine Produkte auswählen. Bereits bezahlte Produkte werden durchgestrichen angezeigt.`,
        },
      ],
    },
    {
      content: [
        {
          paragraph: `Zum Bezahlen stehen dem Gast verschiedene Bezahlmethoden zur Verfügung.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step7.${theme==="ce"?"png":"jpeg"}` },

        {
          paragraph: `Man kann mit Apple Pay, Google Pay, Mastercard, Visa, AMEX, Sofort und Giropay bezahlen. 
          Je nach Konfiguration ist auch die Zahlung via PayPal möglich. `,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wenn ein Gast auf Bezahlen klickt ohne vorher ein Trinkgeld auszuwählen...`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step8.${theme==="ce"?"png":"jpeg"}` },
        {
          paragraph: `..dann tauch dieses Popup auf und fragt ein zweites Mal, welches Trinkgeld hinterlassen werden soll.`,
        },
        {
          paragraph: `Übrigens: 85% der Gäste, die mit dango bezahlen hinterlassen mit dango ein Trinkgeld. Von den anderen 15% gibt es viele, die das Trinkgeld bar auf den Tisch legen. `,
        },
        { paragraph: `Also, immer Augen auf beim Tischabräumen 😉` },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Nach erfolgter Bezahlung sieht der Gast diese Bestätigungsseite.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step9.${theme==="ce"?"png":"jpeg"}` },
        {
          paragraph: `Hier kann der Gast sich einen Beleg herunterladen (auch als Bewirtungsbeleg möglich). `,
        },
        {
          paragraph: `Außerdem kann der Gast eine Bewertung hinterlassen. Hier kann der Gast auch in ein Textfeld etwas reinschreiben.`,
        },
        {
          paragraph: `Wenige Sekunden nach der Zahlung seht ihr in eurer Kasse, dass der Tisch rausgelassen ist. Wenn nur ein Teil der Rechnung bezahlt wurde verschwinden nur die bezahlten Artikel vom Tisch.`,
        },
      ],
    },
    {
      content: [
        { bold_paragraph: `Stell dir nun folgende Situation vor:` },
        {
          paragraph: `Dein Gast scannt den QR-Code um zu zahlen, klickt auf "Jetzt bezahlen" und sieht dann diese Fehlermeldung. `,
        },
    
          { image: `${process.env.PUBLIC_URL}/training_assets/${theme==="ce"?"extrablatt/":""}step10.${theme==="ce"?"png":"jpeg"}` },
   

        {
          question: {
            title: `Woran kann das liegen?`,
            type: 'MULTIPLE_CHOICE',
            choices: [
              {
                label:
                  'Mein Gast hat den QR-Code gescannt ohne vorher die dango App zu installieren.',
                shouldBe: false,
                explanation:
                  'Es gibt keine dango app. Dango öffnet sich im Browser.',
              },
              {
                label:
                  'Ich habe die Bestellung des Gastes auf die falsche Tischnummer bonniert.',
                shouldBe: true,
                explanation:
                  'Jeder QR-Code ist nämlich an eine Tischnummer gekoppelt.',
              },
              {
                label:
                  'Der QR-Code gehört zu einem anderen Tisch und wurde versehentlich auf den Tisch meines Gastes gelegt.',
                shouldBe: true,
              },
              {
                label: `Mein Gast hat den QR-Code gescannt und auf "Jetzt bezahlen" geklickt bevor er überhaupt etwas bestellt hat.`,
                shouldBe: true,
              },
            ],
          },
        },
      ],
    },
  ],
});

const section2 = (theme="hig") => ({
  title: 'Warum nutzen wir dango?',
  pages: [
    {
      content: [
        {
          bold_paragraph:
            'Durch die Nutzung von dango wirst du bei der Bezahlung entlastet und musst viel weniger hin und her laufen. ',
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_a.png` },
        {
          paragraph: `Du brauchst bei der Bezahlung nicht mehr das EC-Gerät holen und auch nicht mehr den Betrag manuell eingeben. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_c.png` },
        {
          paragraph: `Und dank des digitalen Belegs musst du nicht mehr den Bon beim Drucker holen gehen.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_b.png` },
        {
          paragraph: `Und die unnötige Papierverschwendung für's tägliche Bondrucken sparen wir uns auch.`,
        },
        { bold_paragraph: `Und was auch noch wegfällt:` },
        {
          paragraph: `Du musst nicht mehr rumrechnen, wenn Gäste getrennt bezahlen. Mit dango können das die Gäste selber machen.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_d.gif` },
        { paragraph: `Hört sich doch schon viel entspannter an, oder? 😉` },
      ],
    },
    {
      content: [
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p2.png` },
        {
          paragraph: `Mit dango schon! Für alles was über dango bezahlt wurde gibt es beim Tagesabschluss nur EINE EINZIGE Zahlart - nämlich dango - und diese wird automatisch an die Kasse übermittelt.`,
        },
        {
          paragraph: `Das ermöglicht dir einen super schnellen Tagesabschluss, weil du eben nur diese EINE Zahl der Kasse mit dem dango-Report abgleichen müsst. `,
        },
        { paragraph: `Ab in den Feierabend ohne Belege zu zählen.` },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wenn mittags die Gäste alle gleichzeitig bezahlen wollen, müssen sie häufig länger warten. Oft führt das zu negativen Reviews.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p3.png` },
        {
          paragraph: `Über dango können Gäste bezahlen ohne zu warten. So bleibt die Laune gut und es gibt weniger Stress für dich.`,
        },
        {
          paragraph: `Auf deinem Orderman bzw. in der Kasse wirst du in Echtzeit über die Bezahlung informiert. Die Gäste, die weg wollen, müssen nicht warten und du hast noch mehr Zeit für deine anderen Gäste.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p3b.png` },
      ],
    },
    {
      content: [
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p4.png` },
        { bold_paragraph: `Das Beste kommt zum Schluss` },
        {
          paragraph: `Wir konnten feststellen, dass sich das Trinkgeld erhöht, wenn die Gäste über dango bezahlen. So zahlen Gäste im Schnitt etwa 28% mehr als bei Karten- oder Barzahlungen. Wenn also viele Gäste über dango zahlen, kann sich das ganz schön lohnen.`,
        },
        {
          paragraph: `Dein Trinkgeld siehst im Dashboard und natürlich auf deinem Kassenabschluss.`,
        },
      ],
    },
  ],
});

const section3 = (theme="hig") => ({
  title: 'Woher weiß ich, dass ein Gast bezahlt hat?',
  pages: [
    {
      content: [
        {
          bold_paragraph: `Die Zahlung vom Gast ist in der Kasse direkt sichtbar und der Tisch wird automatisch rausgelassen. `,
        },
        {
          paragraph: `In seltenen Fällen kann es bis zu 20 Sekunden dauern, bis der Tisch rausgelassen wird.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Zusätzlich gibt es noch das Dashboard, in dem alle Zahlungen in Echtzeit angezeigt werden`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s3p2.png` },
        { paragraph: `Grün = der Tisch vollständig bezahlt wurde. ` },
        { paragraph: `Gelb = der Tisch wurde nur teilweise bezahlt.` },
        {
          paragraph: `Siehst du eine Zahlung im Dashboard, dann ist der angezeigte Betrag zu 100% bei uns eingegangen.  `,
        },
        {
          bold_paragraph: `Das heißt aber auch: Wenn eine Zahlung dort nicht auftaucht, dann hat der Gast auch nicht über dango bezahlt.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `In ganz seltenen Fällen geht eine Bezahlung durch, der Tisch wird in deiner Kasse aber nicht rausgelassen. In diesem Fall MUSST du ins Dashboard schauen.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s3p3.png` },
        {
          paragraph: `Zahlungen, die zwar eingegangen sind, aber nicht an die Kasse kommuniziert wurden, sind rot statt grün.`,
        },
        {
          bold_paragraph: `Achtung! Wenn das passiert musst du:`,
        },
        { paragraph: `(a) kontrollieren, ob die Summe stimmt ` },
        { paragraph: `(b) das Trinkgeld manuell eintragen ` },
        {
          paragraph: `(c) den Tisch manuell auf dem Finanzweg dango rauslassen `,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: theme==="ce"?`Dein Betriebsletier zeigt, dir wo du das dango Trinkgeld beim Tagesabschluss einsehen kannst.`:`Beim Tagesabschluss erscheinen alle dango-Zahlungen unter “Debitor” in der Zeile “Dango” und das Trinkgeld  wird genauso wie bei der EC-Karten-Bezahlung unter “Abzugebender Betrag” angezeigt. `,
        },
        theme==="ce"?{}:{ image: `${process.env.PUBLIC_URL}/training_assets/s3p4.png` },
      ],
    },
    {
      content: [
        {
          question: {
            title: `Was machst du, wenn ein Gast sagt er habe gezahlt, aber die Zahlung ist in der Kasse nicht sichtbar?`,
            type: 'MULTIPLE_CHOICE',
            choices: [
              {
                label:
                  'Ich glaube, was der Gast sagt und lasse den Tisch manuell über dango raus.',
                shouldBe: false,
                explanation: ' ',
              },
              {
                label:
                  'Die Zahlung vom Gast ist offensichtlich nicht durch, deshalb bitte ich ihn noch Mal in bar oder mit Karte zu zahlen.',
                shouldBe: false,
                explanation:
                  'Es kann nämlich (in ganz seltenen Fällen) sein, dass die Zahlung durch ist, aber die Kommunikation mit der Kasse nicht funktioniert hat.',
              },

              {
                label: `Ich schaue ins Dashboard, ob die Zahlung dort auftaucht. Wenn ja, lasse ich den Tisch  manuell über dango raus. Wenn nein, muss der Gast die Zahlung wiederholen.`,
                shouldBe: true,
              },
            ],
          },
        },
      ],
    },
    {
      content: [
        {
          question: {
            title: `Was musst getan werden, wenn eine Zahlung im dango-Dashboard rot ist?`,
            type: 'MULTIPLE_CHOICE',
            choices: [
              {
                label:
                  'Der Betrag im Dashboard muss mit der Rechnung des Tisches laut Kasse verglichen werden',
                shouldBe: true,
                explanation:
                  'Da bei roten Zahlungen die Kommunikation mit der Kasse nicht funktioniert hat, muss händisch geprüft werden ob der Betrag stimmt.',
              },
              {
                label:
                  'Das Trinkgeld muss - nach Absprache mit der Schichtleitung - manuell eingetragen werden',
                shouldBe: true,
                explanation:
                  'Da bei roten Zahlen die Kommunikation mit der Kasse nicht funktioniert hat, konnte dango der Kasse vielleicht nicht übermitteln wie viel Trinkgeld gezahlt wurde.',
              },
              {
                label: `Der Tisch muss - nach Absprache mit der Schichtleitung - im Orderman auf den Finanzweg dango rausgelassen werden.`,
                shouldBe: true,
                explanation: `Das ist wichtig, damit der Tagesabschluss stimmt.`,
              },
            ],
          },
        },
      ],
    },
    {
      content: [
        {
          question: {
            title: `Lies dir folgende Aussagen durch und markiere Sie als korrekt oder falsch?`,
            type: 'TRUE_OR_FALSE',
            choices: [
              {
                label:
                  'Es kann sein, dass ein Gast erfolgreich gezahlt hat, aber die Zahlung weder in der Kasse bzw. im Orderman noch im Dashboard auftaucht.',
                shouldBe: false,
                explanation:
                  'Eine erfolgreiche Zahlung ist immer im Dashboard zu sehen und in 99% aller Fälle in der Kasse bzw. im Orderman.',
              },
              {
                label:
                  'Eine gelbe Bezahlung im Dashboard bedeutet, dass die Zahlung nur eine Teilzahlung war.',
                shouldBe: true,
                explanation: '',
              },
              {
                label: `Wenn von 2 Gästen an einem Tisch einer seinen Teil über dango zahlt, muss der andere seinen Teil auch mit dango bezahlen.`,
                shouldBe: false,
                explanation: `Dango ist nur eine zusätzliche Bezahlmethode und kann mit anderen Zahlungsformen kombiniert werden.`,
              },
              {
                label: `Gäste können über dango einen digitalen Bewirtungsbeleg generieren. `,
                shouldBe: true,
                explanation: ``,
              },
              {
                label: `Gäste müssen eine  App installieren um dango nutzen zu können.`,
                shouldBe: false,
                explanation: `Es muss weder eine App heruntergeladen werden noch muss man sich registrieren.`,
              },
              {
                label: `Wenn im Dashboard steht 0,00 Euro Trinkgeld, heißt das immer, dass der Gast kein Trinkgeld gezahlt hat.`,
                shouldBe: false,
                explanation: `Viele Gäste, die über dango bezahlen lassen das Trinkgeld lieber in Bar auf dem Tisch. Daher bedeutet 0 Euro nur, dass das Trinkgeld nicht über dango gezahlt wurde, aber nicht zwangsläufig, dass garnichts gezahlt wurde.`,
              },
            ],
          },
        },
      ],
    },
  ],
});

const section4 = (theme="hig") => ({
  title: 'Wie mache ich die Gäste auf dango aufmerksam?',
  pages: [
    {
      content: [
        {
          paragraph:
            'Damit du und deine Gäste die Vorteile nutzen können, sollten möglichst viele Gäste mit dango bezahlen. Um das zu erreichen solltest du die Gäste einfach kurz auf dango hinweisen.',
        },
        { bold_paragraph: 'Aber wann ist dafür der richtige Zeitpunkt? 🤔' },
      ],
    },
    {
      content: [
        {
          bold_paragraph:
            'Generell musst du entscheiden, welcher Ansatz dir liegt und was besser ankommt. Manchmal hängt es ja auch vom Typ der Gäste ab. Generell gibt es 3 Ansätze.',
        },
      ],
    },
    
    {
      content: [
        { paragraph: '' },
        {
          paragraph: `Wenn du den Tisch abräumst, kannst du die Gäste darauf hinweisen, dass sie den QR-Code scannen und digital bezahlen können.`,
        },
        { paragraph: `Beispiel:` },
        {
          bold_paragraph: `“Ich hoffe, es hat euch geschmeckt. Wenn ihr möchtet, scannt Mal unseren neuen QR-Code. Ihr könnt da eure Rechnung einsehen, sie untereinander aufteilen und digital bezahlen. Eine App braucht ihr dafür nicht und ich sehe auf meinem Gerät, wenn ihr bezahlt habt.”`,
        },
      ],
    },
    {
      content: [
        { paragraph: '' },
        {
          paragraph: `Wenn du zum Zahlen an den Tisch gehst, kannst du statt zu fragen "bar oder mit Karte" die Gäste darauf aufmerksam machen, dass sie per QR-Code mit ihrem Handy zahlen können.   `,
        },
        { paragraph: `Beispiel:` },
        {
          bold_paragraph: `“Möchtet ihr unser neues Bezahlsystem testen? Wenn ihr den QR-Code scannt, sehr ihr die Rechnung und könnt mit dem Handy bezahlen. Ich sehe eure Zahlung dann hier in meinem Gerät.”`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wichtig: Zahlen mit QR-Code heißt nicht, dass der Gast grundsätzlich ohne dich seine Rechnung bezahlt. `,
        },
        {
          paragraph: `Wenn die Situation es zulässt, kannst du also einfach am Tisch stehen bleiben und die Gäste in Ruhe verabschieden, statt mit dem EC-Kartengerät zu hantieren.`,
        },
        {
          paragraph: `Aber wenn die Leute es eilig haben und du gerade im Stress bist, dann ist es nicht schlimm, wenn die Gäste die Option haben zu zahlen ohne auf dich zu warten.`,
        },
      ],
    },
  ],
});

const allg_section1 = () => ({
  title: 'Wie funktioniert pay with dango?',
  pages: [
    {
      content: [
        {
          bold_paragraph: `
                dango ist eine ganz neue Art zu Bezahlen, die speziell für die Gastronomie entwickelt wurde. 
                Dango entlastet das Servicepersonal und macht den Bezahlvorgang für alle Beteiligten einfacher, schneller und komfortabler. 

                `,
        },
        {
          image: `${process.env.PUBLIC_URL}/training_assets/qrcodeontable.png`,
        },

        {
          paragraph:
            'Übrigens: Ihr seid deutschlandweit eines der ersten Restaurants, die diese Lösung anbieten. 😎',
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `
                Im folgenden Video siehst du den Bezahlvorgang aus Sicht eines Gastes.
                `,
        },
        { youtube: 'AzMVq1UQceM' },
        {
          paragraph: `Als nächstes gehen wir das Ganze mal Schritt für Schritt gemeinsam durch.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wenn der Gast fertig gegessen hat und bezahlen möchte, scannt er 
          den QR-Code auf seinem Tisch. Jeder Tisch hat seinen eigenen QR-Code, wo auch die 
          jeweilige Tischnummer kodiert ist.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step1.jpg` },

        {
          paragraph: `Damit dango also funktioniert, ist es wichtig, dass ihr 
        immer auf die richtige Tischnummer bonniert.`,
        },
        {
          paragraph: `
                Der Gast muss normalerweise keine QR-Code Scanner App herunterladen. Einfach nur die 
                normale Handy-Kamera öffnen und auf den QR-Code richten.
                `,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Nach dem Scannen öffnet sich im Browser die Startseite. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step4.jpeg` },
        {
          paragraph: `Von hier können Gäste nicht nur über dango bezahlen, sondern auch die Speisekarte einsehen`,
        },
        {
          bold_paragraph: `Bei Klick auf Speisekarte sieht man die digitale Speisekarte mit zusätzlichen Infos zu Allergenen und Inhalttstoffen. Man kann darüber allerdings NICHT bestellen. Die Bestellaufnahme läuft weiterhin über dich.`,
        },
        {
          paragraph: `Bei Klick auf "Jetzt bezahlen" wird die offene Tisch-Rechnung aus eurer Kasse geladen.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Gäste sehen in der Übersicht alles was auf ihrem Tisch bonniert ist. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step5_leaf.PNG` },
        
        {
          paragraph: `Für das Trinkgeld machen wir vorberechnete Vorschläge. Gäste müssen also nichts ausrechnen, sondern klicken einfach auf die gewünschte Trinkgeldhöhe. Man kann allerdings auch einen eigenen Betrag eingeben.`,
        },
      ],
    },
   
    {
      content: [
        {
          paragraph: `Zum Bezahlen stehen dem Gast verschiedene Bezahlmethoden zur Verfügung.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step7_leaf.PNG` },

        {
          paragraph: `Man kann mit Apple Pay, Google Pay, Mastercard, Visa, AMEX, Sofort und Giropay bezahlen. 
          `,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Wenn ein Gast auf Bezahlen klickt ohne vorher ein Trinkgeld auszuwählen...`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step8.jpeg` },
        {
          paragraph: `..dann tauch dieses Popup auf und fragt ein zweites Mal, welches Trinkgeld hinterlassen werden soll.`,
        },
        {
          paragraph: `Übrigens: 80% der Gäste, die mit dango bezahlen hinterlassen mit dango ein Trinkgeld. Von den anderen 20% gibt es viele, die das Trinkgeld bar auf den Tisch legen. `,
        },
        { paragraph: `Also, immer Augen auf beim Tischabräumen 😉` },
      ],
    },
    {
      content: [
        {
          bold_paragraph: `Nach erfolgter Bezahlung sieht der Gast diese Bestätigungsseite.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/step9.jpeg` },
        {
          paragraph: `Hier kann der Gast sich einen Beleg per Email zuschicken lassen (auch als Bewirtungsbeleg möglich). `,
        },
        {
          paragraph: `Außerdem kann der Gast eine Bewertung hinterlassen. Hier kann der Gast auch in ein Textfeld etwas reinschreiben.`,
        },
        {
          paragraph: `Wenige Sekunden nach der Zahlung seht ihr im Dashboard, dass der Tisch rausgelassen ist.`,
        },
      ],
    },
    {
      content: [
        {
          bold_paragraph:`Das Dashboard zeigt dir alle bezahlten Tische in Echtzeit an`,
          
        },
        {
          image: `${process.env.PUBLIC_URL}/training_assets/mobil_dashboard.jpeg`,
        },
        {
          paragraph: "Das Dashboard ist eine browser-basierte Web-App, die du einfach im Safari oder Chrome Browser deines Mobilgeräts öffnen kannst. "
        },
        {
          link: "https://dashboard.paywithdango.app"
        },
        {
          paragraph: "In diesem Beispiel sind die 34,20 EUR der bezahlte Rechnungsbetrag und 3,50 EUR das Trinkgeld. Insgesamt wurden also 37,70 EUR bezahlt. "
        },
        {
          bold_paragraph: "Wichtig: Wenn du den Tisch in deinem Kassensystem (z.B. Gastronovi) schließt, musst du daran denken das Trinkgeld entsprechend einzutragen."
        },
        {
          paragraph: "Die Zugangsdaten zum Dashboard erhältst du von der Betriebsleitung. Wenn du das erste Mal das Dashboard öffnest speichere es als Favorit, damit du nicht jedes Mal die URL manuell eingeben musst."
        }
      ]
    },
    {
      content: [
        { bold_paragraph: `Stell dir nun folgende Situation vor:` },
        {
          paragraph: `Dein Gast scannt den QR-Code um zu zahlen, klickt auf "Jetzt bezahlen" und sieht dann diese Fehlermeldung. `,
        },
        {
          image: `${process.env.PUBLIC_URL}/training_assets/step10.jpeg`,
        },

        {
          question: {
            title: `Woran kann das liegen?`,
            type: 'MULTIPLE_CHOICE',
            choices: [
              {
                label:
                  'Mein Gast hat den QR-Code gescannt ohne vorher die dango App zu installieren.',
                shouldBe: false,
                explanation:
                  'Es gibt keine dango app. Dango öffnet sich im Browser.',
              },
              {
                label:
                  'Ich habe die Bestellung des Gastes auf die falsche Tischnummer bonniert.',
                shouldBe: true,
                explanation:
                  'Jeder QR-Code ist nämlich an eine Tischnummer gekoppelt.',
              },
              {
                label:
                  'Der QR-Code gehört zu einem anderen Tisch und wurde versehentlich auf den Tisch meines Gastes gelegt (oder geklebt).',
                shouldBe: true,
              },
              {
                label: `Mein Gast hat den QR-Code gescannt und auf "Jetzt bezahlen" geklickt bevor er überhaupt etwas bestellt hat.`,
                shouldBe: true,
              },
            ],
          },
        },
      ],
    },
  ],
});

const allg_section2 = () => ({
  title: 'Warum nutzen wir dango?',
  pages: [
    {
      content: [
        {
          bold_paragraph:
            'Durch die Nutzung von dango wirst du bei der Bezahlung entlastet und musst viel weniger hin und her laufen. ',
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_a.png` },
        {
          paragraph: `Du brauchst bei der Bezahlung nicht mehr das EC-Gerät holen und auch nicht mehr den Betrag manuell eingeben. `,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_c.png` },
        {
          paragraph: `Und dank des digitalen Belegs musst du nicht mehr den Bon beim Drucker holen gehen.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p1_b.png` },
        {
          paragraph: `Und die unnötige Papierverschwendung für's tägliche Bondrucken sparen wir uns auch.`,
        },
       
      ],
    },
 
    {
      content: [
        {
          bold_paragraph: `Wenn mittags die Gäste alle gleichzeitig bezahlen wollen, müssen sie häufig länger warten. Oft führt das zu negativen Reviews.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p3.png` },
        {
          paragraph: `Über dango können Gäste bezahlen ohne zu warten. So bleibt die Laune gut und es gibt weniger Stress für dich.`,
        },
        {
          paragraph: `Auf dem Dashboard wirst du in Echtzeit über die Bezahlung informiert. Die Gäste, die weg wollen, müssen nicht warten und du hast noch mehr Zeit für deine anderen Gäste.`,
        },
        { image: `${process.env.PUBLIC_URL}/training_assets/s2p3b.png` },
      ],
    },
    {
      content: [
         { bold_paragraph: `Was ist mit Trinkgeld?` }, 
        {
          paragraph: `Wir konnten feststellen, dass sich das Trinkgeld erhöht, wenn die Gäste über dango bezahlen. So bekommst du im Durchschnitt etwa 28% mehr als bei Zahlungen ohne dango. Wenn also viele Gäste über dango zahlen, kann sich das ganz schön lohnen.`,
        },
        {
          paragraph: `Das abgegebene Trinkgeld siehst du im Dashboard in der Zeile der jeweiligen Zahlung.`,
        },
      ],
    },
  ],
});

// const allg_section3 = () => {
//   title: 'Woher weiß ich, dass ein Gast bezahlt hat?',
//   pages: [
//     {
//       content: [
//         {
//           bold_paragraph: `Die Zahlung vom Gast ist im Orderman direkt sichtbar und der Tisch wird automatisch rausgelassen. `,
//         },
//         { image: `${process.env.PUBLIC_URL}/training_assets/s3p1.png` },
//         {
//           paragraph: `In seltenen Fällen kann es bis zu 25 Sekunden dauern, bis die Zahlung im Orderman auftaucht.`,
//         },
//       ],
//     },
//     {
//       content: [
//         {
//           bold_paragraph: `Zusätzlich gibt es noch das Dashboard, in dem alle Zahlungen in Echtzeit angezeigt werden`,
//         },
//         { image: `${process.env.PUBLIC_URL}/training_assets/s3p2.png` },
//         { paragraph: `Grün = der Tisch vollständig bezahlt wurde. ` },
//         { paragraph: `Gelb = der Tisch wurde nur teilweise bezahlt.` },
//         {
//           paragraph: `Siehst du eine Zahlung im Dashboard, dann ist der angezeigte Betrag zu 100 % bei uns eingegangen.  `,
//         },
//         {
//           paragraph: ` Das heißt aber auch: Wenn eine Zahlung dort nicht auftaucht, dann hat der Gast auch nicht über dango bezahlt.`,
//         },
//       ],
//     },
//     {
//       content: [
//         {
//           bold_paragraph: `In ganz seltenen Fällen geht eine Bezahlung durch, wird aber nicht im Orderman angezeigt. In diesem Fall MUSST du ins Dashboard schauen.`,
//         },
//         { image: `${process.env.PUBLIC_URL}/training_assets/s3p3.png` },
//         {
//           paragraph: `Zahlungen, die zwar eingegangen sind, aber nicht an den Orderman kommuniziert, sind rot statt grün.`,
//         },
//         {
//           bold_paragraph: `Achtung! Wenn das passiert musst du nach Absprache mit der Schichtleitung:`,
//         },
//         { paragraph: `(a) kontrollieren, ob die Summe stimmt ` },
//         { paragraph: `(b) das Trinkgeld manuell eintragen ` },
//         {
//           paragraph: `(c) den Tisch manuell auf dem Finanzweg dango rauslassen `,
//         },
//       ],
//     },
//     {
//       content: [
//         {
//           bold_paragraph: `Beim Tagesabschluss erscheinen alle dango-Zahlungen unter “Debitor” in der Zeile “Dango” und das Trinkgeld  wird genauso wie bei der EC-Karten-Bezahlung unter “Abzugebender Betrag” angezeigt. `,
//         },
//         { image: `${process.env.PUBLIC_URL}/training_assets/s3p4.png` },
//       ],
//     },
//     {
//       content: [
//         {
//           question: {
//             title: `Was machst du, wenn ein Gast sagt er habe gezahlt, aber die Zahlung ist im Orderman nicht sichtbar?`,
//             type: 'MULTIPLE_CHOICE',
//             choices: [
//               {
//                 label:
//                   'Ich glaube, was der Gast sagt und lasse den Tisch auf dem Orderman über dango raus.',
//                 shouldBe: false,
//                 explanation: ' ',
//               },
//               {
//                 label:
//                   'Die Zahlung vom Gast ist offensichtlich nicht durch, deshalb bitte ich ihn noch Mal in bar oder mit Karte zu zahlen.',
//                 shouldBe: false,
//                 explanation:
//                   'Es kann nämlich (in ganz seltenen Fällen) sein, dass die Zahlung durch ist, aber die Kommunikation mit dem Orderman nicht funktioniert hat.',
//               },

//               {
//                 label: `Ich schaue ins Dashboard, ob die Zahlung dort auftaucht. Wenn ja, lasse ich den Tisch  manuell über dango raus. Wenn nein, muss der Gast die Zahlung wiederholen.`,
//                 shouldBe: true,
//               },
//             ],
//           },
//         },
//       ],
//     },
//     {
//       content: [
//         {
//           question: {
//             title: `Was musst getan werden, wenn eine Zahlung im dango-Dashboard rot ist?`,
//             type: 'MULTIPLE_CHOICE',
//             choices: [
//               {
//                 label:
//                   'Der Betrag im Dashboard muss mit dem Betrag im Orderman verglichen werden',
//                 shouldBe: true,
//                 explanation:
//                   'Da bei roten Zahlungen die Kommunikation mit dem Orderman nicht funktioniert hat, muss händisch geprüft werden ob der Betrag stimmt.',
//               },
//               {
//                 label:
//                   'Das Trinkgeld muss - nach Absprache mit der Schichtleitung - händisch im Orderman eingetragen werden',
//                 shouldBe: true,
//                 explanation:
//                   'Da bei roten Zahlen die Kommunikation mit dem Orderman nicht funktioniert hat, konnte dango dem Orderman vielleicht nicht übermitteln wie viel Trinkgeld gezahlt wurde.',
//               },
//               {
//                 label: `Der Tisch muss - nach Absprache mit der Schichtleitung - im Orderman auf den Finanzweg dango rausgelassen werden.`,
//                 shouldBe: true,
//                 explanation: `Das ist wichtig, damit der Tagesabschluss stimmt.`,
//               },
//             ],
//           },
//         },
//       ],
//     },
//     {
//       content: [
//         {
//           question: {
//             title: `Lies dir folgende Aussagen durch und markiere Sie als korrekt oder falsch?`,
//             type: 'TRUE_OR_FALSE',
//             choices: [
//               {
//                 label:
//                   'Es kann sein, dass ein Gast erfolgreich gezahlt hat, aber die Zahlung weder in der Kasse bzw. im Orderman noch im Dashboard auftaucht.',
//                 shouldBe: false,
//                 explanation:
//                   'Eine erfolgreiche Zahlung ist immer im Dashboard zu sehen und in 99% aller Fälle in der Kasse bzw. im Orderman.',
//               },
//               {
//                 label:
//                   'Eine gelbe Bezahlung im Dashboard bedeutet, dass die Zahlung nur eine Teilzahlung war.',
//                 shouldBe: true,
//                 explanation: '',
//               },
//               {
//                 label: `Wenn von 2 Gästen an einem Tisch einer seinen Teil über dango zahlt, muss der andere seinen Teil auch mit dango bezahlen.`,
//                 shouldBe: false,
//                 explanation: `Dango ist nur eine zusätzliche Bezahlmethode und kann mit anderen Zahlungsformen kombiniert werden.`,
//               },
//               {
//                 label: `Gäste können über dango einen digitalen Bewirtungsbeleg generieren. `,
//                 shouldBe: true,
//                 explanation: ``,
//               },
//               {
//                 label: `Gäste müssen eine kleine App installieren um dango nutzen zu können.`,
//                 shouldBe: false,
//                 explanation: `Es muss weder eine App heruntergeladen werden noch muss man sich registrieren.`,
//               },
//               {
//                 label: `Wenn im Dashboard steht 0,00 Euro Trinkgeld, heißt das immer, dass der Gast kein Trinkgeld gezahlt hat.`,
//                 shouldBe: false,
//                 explanation: `Viele Gäste, die über dango bezahlen lassen das Trinkgeld lieber in Bar auf dem Tisch. Daher bedeutet 0 Euro nur, dass das Trinkgeld nicht über dango gezahlt wurde, aber nicht zwangsläufig, dass garnichts gezahlt wurde.`,
//               },
//             ],
//           },
//         },
//       ],
//     },
//   ],
// };

const allg_section4 =() => ({
  title: 'Wie mache ich die Gäste auf dango aufmerksam?',
  pages: [
    {
      content: [
        {
          paragraph:
            'Damit du und deine Gäste die Vorteile nutzen können, sollten möglichst viele Gäste mit dango bezahlen. Um das zu erreichen solltest du die Gäste einfach kurz auf dango hinweisen.',
        },
        { bold_paragraph: 'Aber wann ist dafür der richtige Zeitpunkt? 🤔' },
      ],
    },
    {
      content: [
        {
          bold_paragraph:
            'Generell musst du entscheiden, welcher Ansatz dir liegt und was besser ankommt. Manchmal hängt es ja auch vom Typ der Gäste ab. Generell gibt es 3 Ansätze.',
        },
      ],
    },
    {
      content: [
        { paragraph: '' },
        {
          paragraph: `Schon wenn die Gäste zu euch ins Restaurant kommen und sich setzen, kannst du sie auf dango aufmerksam machen.  `,
        },
        {
          paragraph: `  Sie können über den QR-Code nämlich nicht nur bezahlen, sondern auch die digitale Speisekarte einsehen.`,
        },
        { paragraph: `Beispiel:` },
        {
          bold_paragraph: `“Ihr könnt unseren neuen QR-Code scannen um die digitale Speisekarte zu sehen. Ich komme gleich um eure Bestellung aufzunehmen. Übrigens: Später könnt ihr auch über den QR-Code bezahlen.”`,
        },
      ],
    },
    {
      content: [
        { paragraph: '' },
        {
          paragraph: `Wenn du den Tisch abräumst, kannst du die Gäste darauf hinweisen, dass sie den QR-Code scannen und digital bezahlen können.`,
        },
        { paragraph: `Beispiel:` },
        {
          bold_paragraph: `“Ich hoffe, es hat euch geschmeckt. Wenn ihr möchtet, scannt Mal unseren neuen QR-Code. Ihr könnt da eure Rechnung einsehen, sie untereinander aufteilen und digital bezahlen. Eine App braucht ihr dafür nicht und ich sehe auf meinem Gerät, wenn ihr bezahlt habt.”`,
        },
      ],
    },
    {
      content: [
        { paragraph: '' },
        {
          paragraph: `Wenn du zum Zahlen an den Tisch gehst, kannst du statt zu fragen "bar oder mit Karte" die Gäste darauf aufmerksam machen, dass sie per QR-Code mit ihrem Handy zahlen können.   `,
        },
        { paragraph: `Beispiel:` },
        {
          bold_paragraph: `“Möchtet ihr unser neues Bezahlsystem testen? Wenn ihr den QR-Code scannt, sehr ihr die Rechnung und könnt mit dem Handy bezahlen. Ich sehe eure Zahlung dann hier in meinem Gerät.”`,
        },
      ],
    },
    {
      content: [
        {
          paragraph: `Wichtig: Zahlen mit QR-Code heißt nicht, dass der Gast grundsätzlich ohne dich seine Rechnung bezahlt. `,
        },
        {
          paragraph: `Wenn die Situation es zulässt, kannst du also einfach am Tisch stehen bleiben und die Gäste in Ruhe verabschieden, stat mit dem EC-Kartengerät zu hantieren.`,
        },
        {
          paragraph: `Aber wenn die Leute es eilig haben und du gerade im Stress bist, dann ist es nicht schlimm, wenn die Gäste die Option haben zu zahlen ohne auf dich zu warten.`,
        },
      ],
    },
  ],
});

const data = [
  {
    title: 'Grundschulung pay with dango (amadeus)',
    hidden: false,

    id: 1,
    sections: [section1(), section2(), section3(), section4()],
  },
  // title: 'Schulung für BLs',
  //   id: 2936192,
  //   hidden: true,
  {
    title: "BL-Schulung (Allgemein)",
    id: 2936195,
    hidden: true,
    sections: [
      {
        title: "Login ins dango-Dashboard",
        pages: [{
          content: [
            { bold_paragraph: "Wir legen für dich und dein Team je nach Wunsch einen oder mehrere unterschiedliche Accounts für unser dango-Dashboard an. Die Emailadresse(n) teilen wir dir in einer separaten Email mit." },
           ]
        },
        ...login_dashboard_allg,
        ]
      },
     

      ...qrcode_mapping_section,
      ...einfuerhung_dango_dashboard,
      ]
  }
  ,
  {
    title: "BL-Schulung (HiG)",
    id: 2936192,
    hidden: true,
    sections: [
      {
        title: "Login ins dango-Dashboard",
        pages: [{
          content: [
            { bold_paragraph: "Wir legen für dich und dein Team 2 unterschiedliche Accounts für unser dango-Dashboard an:" },
            { paragraph: "▶️ bl.standort@-Email-Adresse" },
            { paragraph: "▶️ standort@-Email-Adresse" },
            { paragraph: "Mit dem Account der BL kannst du im Dashboard etwas mehr Informationen einsehen, als der Rest deines Teams (z.B. Kunden-Feedback)." }
          ]
        },
        ...login_dashboard_hig,
        ]
      },
      {
        title: "Mitarbeiter-Schulung",
        pages: [
          {
            content: [
              { bold_paragraph: "Dein bestehendes Team und auch neue Teammitglieder sollen sich so schnell wie möglich mit unserem dango vertraut machen." },
              { bold_paragraph: "Hierfür haben wir eine Schulungs-App entwickelt." },
              { bold_paragraph: "https://training.paywithdango.com/train" },
              { paragraph: "⏱️ Dauer: nur 10 - 15 Minuten" },
              { paragraph: "🤓 Durchführung: online und vollkommen selbständig " },
              { paragraph: "📱 Über Link zur Webseite, kein App-Download" },
              { bold_paragraph: "Bitte sende den Link für die Schulungs-App 1 - 2 Tage vor dem Go-Live-Termin an dein gesamtes Team." },
              { bold_paragraph: "Weise sie darauf hin, dass sie die Schulung unbedingt bis zum Ende durchführen sollen." }
            ]
          }
        ]
      },

      ...qrcode_mapping_section,
      ...einfuerhung_dango_dashboard,
      ]
  }
  ,
  {
    title: "BL-Schulung (Mapping-App)",
    id: 2936193,
    hidden: true,
    sections: [
      ...qrcode_mapping_section
    ]
  },

  {
    title: 'Grundschulung pay with dango (GN)',
    hidden: true,
    zusatzinfo: "GN-friendly",
    id: 101,
    sections: [allg_section1(), allg_section2(),   allg_section4()],
  },

  {
    title: 'Grundschulung pay with dango (Matrix)',
    hidden: true,
    id: 1052349,
    sections: [section1("ce"), section2("ce"), section3("ce"), section4("ce")],
  },
  {
    title: "BL-Schulung (Gastronovi)",
    id: 2936199,
    hidden: true,
    sections: [
      {
        title: "Login ins dango-Dashboard",
        pages: [{
          content: [
            { bold_paragraph: "Wir legen für dich und dein Team je nach Wunsch einen oder mehrere unterschiedliche Accounts für unser dango-Dashboard an. Die Emailadresse(n) teilen wir dir in einer separaten Email mit." },
           ]
        },
        ...login_dashboard_allg,
        ]
      },
     

      ...qrcode_mapping_section,
      {
        title: "Einführung ins dango-Dashboard",
        pages: [{
          content: [
            { bold_paragraph: "Das zu Beginn erwähnte dango-Dashboard ermöglicht euch einige Vorteile: " },
            { paragraph: "- Live-Ansicht aller Bezahlungen über dango" },
            { paragraph: "- Erstellen eines Tagesberichts" },
            { paragraph: "- Erstellen eines Detailberichts " },
            { paragraph: "- Auswerten von Kunden-Feedback" }]
        },
        {
          content: [{ bold_paragraph: "Live-Ansicht" },
          { paragraph: "Im Dashboard werden in der Live-Ansicht alle Bezahlungen der letzten 8 Stunden über dango angezeigt. " },
          { image: "https://dineup.imgix.net/dashboard/Schulung.png" },
          ]
        },
        {
          content: [
            {
              bold_paragraph:`Das Dashboard zeigt alle bezahlten Tische in Echtzeit an`,
              
            },
            {
              image: `${process.env.PUBLIC_URL}/training_assets/mobil_dashboard.jpeg`,
            },
            {
              paragraph: "Das Dashboard ist eine browser-basierte Web-App, die du einfach im Safari oder Chrome Browser deines Mobilgeräts öffnen kannst. "
            },
            {
              link: "https://dashboard.paywithdango.app"
            },
            {
              paragraph: "In diesem Beispiel sind die 34,20 EUR der bezahlte Rechnungsbetrag und 3,50 EUR das Trinkgeld. Insgesamt wurden also 37,70 EUR bezahlt. "
            },
            {
              bold_paragraph: "Wichtig: Wenn du den Tisch in deinem Kassensystem (z.B. Gastronovi) schließt, musst du daran denken das Trinkgeld entsprechend einzutragen."
            },
            {
              paragraph: "Jeder Mitarbeiter sollte Zugang zum Dashboard haben um die bezahlten Tische zu sehen und die Zahlung entsprechend in der Kasse zu hinterlegen. Intenr solltet ihr euch auf einen gesonderten Bezahlweg einigen auf dem die Dango Zahlungen rausgelassen werden."
            }
          ]
        },
        {
          content: [{ bold_paragraph: "Tagesbericht" },
          { paragraph: "Im Tagesbericht findest du eine Auflistung aller Zahlungen und Kennzahlen des aktuellen Tages." },
          { paragraph: "Hier kannst du auch den Tagesbericht von allen Zahlungen über dango auf Papier ausdrucken und mit dem Z-Bericht eurer Kasse abgleichen." },
          { image: "https://dineup.imgix.net/Schulung/Tagesansicht.png" },
          { bold_paragraph: "Egal, welche Zahlart die Gäste mit dango nutzen, du hast am Ende des Tages nur eine einzige Zahl, die du mit deinem Z-Bericht in Amadeus abgleichen musst." },
          { paragraph: "Je mehr Gäste also über dango bezahlen, umso schneller bist du mit dem Tagesabschluss fertig und kannst in den Feierabend." },
          { image: "https://dineup.imgix.net/Schulung/Kassenbericht.png" },
          { paragraph: "💡Übrigens: Das Format des Tagesberichts aus dango, könnt ihr ganz einfach im Dashboard unter “Einstellungen” ändern - entweder ihr nutzt das Format A4 oder aber das Bon-Format, wenn ihr den Bericht über euren Bon-Drucker ausgeben lassen möchtet." }]
        },
        {
          content: [{ bold_paragraph: "Detailbericht" },
          { paragraph: "Der Detailbericht ähnelt dem Tagesbericht sehr, ist jedoch nicht für einen einzigen Tag, sondern für einen definierten Zeitraum (z.B. für einen Monat)." },
          { image: "https://dineup.imgix.net/Schulung/Detailbericht.png" },
    
          ]
        },
        {
          content: [{ bold_paragraph: "Kunden-Feedback" },
          { paragraph: "Wir fragen jeden deiner Gäste nach der Bezahlung, wie ihm der Besuch bei euch gefallen hat." },
          { paragraph: "Wenn sie möchten, haben deine Gäste hier die Möglichkeit eine Sternebewertung abzugeben (1 bis 5 Sterne ⭐)." },
          { paragraph: "Bei 5 Sternen werden die Gäste mit einem Klick zu Google Review weitergeleitet und bei weniger als 5 Sternen, werden sie zu Lob und Tadel weitergeleitet. " },
          { bold_paragraph: "Die Sternebewertungen ⭐ deiner Gäste findest du im Dashboard unter Feedback." },
          { image: "https://dineup.imgix.net/Schulung/Kunden-Feedback.png" },
          { paragraph: "Dort siehst du eine Auflistung aller Bewertungen, die Zuordnung zu der entsprechenden Bestellung und, ob der Gast tatsächlich auf Lob oder Tadel bzw. Google Review geklickt hat." }]
        }]
      }
      ]
  }
];

// sections: [  section1(), section2(), section3(), section4()],
export default data;

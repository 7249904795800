import React from 'react';

const Circle = ({ number, isDone, isCurrent }) => {
  return (
    <span
      className={`badge  rounded-pill mx-1 ${isDone
          ? 'text-bg-dark'
          : isCurrent
            ? 'text-bg-primary'
            : 'text-bg-light'
        }`}>
      {number}
    </span>
  );
};
export default function ProgressBar({ pages, currentPage }) {
  return (
    <div 
    onClick={()=>{alert("Um weiter zu machen oder zurück zu gehen, benutze die Buttons ganz unten.")}}
    className="d-flex w-100 flex-row justify-content-center mb-2">
      {[...Array(pages)].map((e, i) => (
        <Circle
          key={i}
          number={i + 1}
          isDone={i < currentPage}
          isCurrent={currentPage === i}
        />
      ))}
    </div>
  );
}

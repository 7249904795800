import React, { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import QuestionBlock from './QuestionBlock';
import NextButtonBar from './NextButtonBar';
import Img from "./Img"
import Markdown from 'markdown-to-jsx';



export default function Page({
  page,
  hasNextPage,
  goToNextPage,
  hasNextSection,
  goToNextSection,
  hasPrevPage,
  goToPrevPage,
  hasPrevSection,
  goToPrevSection,
  env,
  cid
}) {
  const offerNextPage = page.content.every(
    (c) => Object.keys(c)[0] !== 'question',
  );

  const [noOpenQuestions, setNoOpenQuestions] = useState(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("scroll triggered")
    setNoOpenQuestions(null)
  }, [page]);

  console.log('render');
  const content = page.content;

  const print = content.map((c) => {
    const blockType = Object.keys(c)[0];
    const blockData = Object.values(c)[0];

    if (blockType === 'markdown')
      return (
        <div className="mb-2">
          <div className="text-start" style={{ fontSize: "16px", lineHeight: "140%" }}>

            <Markdown key={blockData.substring(0, 20)} c options={{}}>
              {blockData}

            </Markdown>
          </div>
        </div>
      ); if (blockType === 'paragraph')
      return (
        <div className="mb-2">
          <p key={blockData.substring(0, 20)} className="text-start  " style={{ fontSize: "16px", lineHeight: "140%" }}>
            {blockData}
          </p>
        </div>
      );
    if (blockType === 'bold_paragraph')
      return (
        <div className="mb-2">
          <p key={blockData.substring(0, 20)} className="text-center   " style={{ fontSize: "16px", lineHeight: "140%" }}>
            <b>{blockData}</b>
          </p>
        </div>
      );

    if (blockType === 'image')
      return <div className='pb-2'>
        <Img
          src={blockData}
          alt="Bild wird geladen.."
          className="img-fluid"
          loader={<ClipLoader />}
        /></div>;

    if (blockType === 'link')
      return <a
        className='d-block  pb-2 mb-2'
        href={blockData}
        rel="noreferrer"
        target="_blank"
      >{blockData}</a>;

    if (blockType === 'youtube')
      return (
        <iframe
          width="100%"
          style={{ height: '90vh' }}
          src={`https://www.youtube.com/embed/${blockData}?rel=0`}
          title=" Video abspielen"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      );

    if (blockType === 'question') {
      return (
        <QuestionBlock
          markSolved={() => {
            setNoOpenQuestions(true);
            // setOfferNextPage(true);
          }}
          question={blockData}
        />
      );
    }
    if(Object.keys(c).length===0) return <></>
    return <b>unknown content type</b>;
  });

  return (
    <>
      <div className="mb-5 p-3">
        {print.map((p, i) => (
          <div key={i}>{p}</div>
        ))}
        <NextButtonBar
          offerNextPage={offerNextPage || noOpenQuestions === true}
          hasNextPage={hasNextPage}
          goToNextPage={goToNextPage}
          hasNextSection={hasNextSection}
          goToNextSection={goToNextSection}
          hasPrevPage={hasPrevPage}
          goToPrevPage={goToPrevPage}
          hasPrevSection={hasPrevSection}
          goToPrevSection={goToPrevSection}
          env={env}
          cid={cid}
        />
      </div>
    </>
  );
}

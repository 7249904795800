import React, { useState } from 'react'
// import { Routes, Route, useParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import data from '../data';
import Section from "../components/Section"
import AttentionGetter from "../components/AttentionGetter"
import usePreloadImages from '../usePreloadImages';
import CourseOverview from './CourseOverview';

const CourseWrapper = ({ courseId, c_data = null, env = "production" }) => {

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [indexSeen, setIndexSeen] = useState(false)

  const [position, setPosition] = useState({ page: null, section: 0 })


  const course = c_data === null ? data.filter(c => c.id === parseInt(courseId))[0] : c_data



  const goToNextPage = () => {
    if (position.page === null) {
      setPosition({ section: position.section, page: 0 })
    } else {
      setPosition({ section: position.section, page: position.page + 1 })
    }
  }


  const goToPrevPage = () => {
    if (position.page === null) {
      setPosition({ section: position.section, page: 0 })
    } else {
      setPosition({ section: position.section, page: position.page - 1 })
    }
  }

  const goToNextSection = () => {
    setPosition({ section: position.section + 1, page: null })
  }
  const goToPrevSection = () => {
    setPosition({ section: position.section - 1, page: course.sections[position.section - 1].pages.length - 1 })
  }

  const all_images = []

  for (const page of course.sections[position.section].pages) {
    for (const block of Object.values(page.content)) {
      if (Object.keys(block)[0] === "image") {
        all_images.push(block.image)
      }
    }
  }
  console.log("rendering section")

  usePreloadImages(all_images)

  if (!course) {
    return <b>Loading..</b>
  }


  if (!indexSeen) {
    return <CourseOverview course={course} handler={() => { setIndexSeen(true) }} />
  }

  if (!termsAccepted) {
    return <AttentionGetter handler={() => { setTermsAccepted(true) }} />
  }

  return (
    <>
      {/* <Routes> */}
      {/* <Route path={`/course/${courseId}/section/${position.section}/page/${position.page}`}> */}
      <Section
        hasNextSection={(course.sections.length - 1) > position.section}
        hasPrevSection={position.section > 0}
        section={course.sections[position.section]}
        sectionNumber={position.section} page={position.page}
        goToNextSection={goToNextSection}
        goToPrevSection={goToPrevSection}
        goToNextPage={goToNextPage}
        goToPrevPage={goToPrevPage}
        env={env}
        cid={courseId} />
      {/* </Route> */}

      {/* </Routes> */}
    </>

  )
}

export default function Course({ courseId = null, c_data = null, env = "production" }) {
  let params = useParams();
  const actual_courseId = courseId === null ? params.courseId : courseId;

  return <CourseWrapper courseId={actual_courseId} c_data={c_data} env={env} />
}

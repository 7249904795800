import React, { useState } from 'react';
import Course from './Course';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from "ajv"
import { useParams } from 'react-router-dom';
import data from '../data';


const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}

const schema = {
    type: "object",
    properties: {
        title: { type: "string" },
        id: { type: "integer" },
        hidden: { type: "boolean" },
        sections: {
            type: "array",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    title: { type: "string" },
                    pages: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                content: {
                                    type: "array",
                                    minItems: 1,
                                    items: {
                                        type: "object",
                                        properties: {
                                            markdown: { type: "string" },
                                            bold_paragraph: { type: "string" },
                                            paragraph: { type: "string" },
                                            image: { type: "string" },
                                            link: { type: "string" },
                                            youtube: { type: "string" },
                                            question: {
                                                type: "object",
                                                required: ["title", "type", "choices"],
                                                properties: {
                                                    title: { type: "string" },
                                                    type: { enum: ["MULTIPLE_CHOICE", "TRUE_OR_FALSE"] },
                                                    choices: {
                                                        type: "array",
                                                        minItems: 1,
                                                        items: {
                                                            properties: {
                                                                label: { type: "string" },
                                                                shouldBe: { type: "boolean" },
                                                                explanation: { type: "string" }
                                                            },
                                                            required: ["label", "shouldBe"],
                                                            additionalProperties: false
                                                        }
                                                    }

                                                }
                                            },
                                        },
                                        maxProperties: 1,
                                        minProperties: 1,
                                        additionalProperties: false
                                    }
                                }
                            },
                            required: ["content"],
                            additionalProperties: false
                        },
                        minItems: 1,
                    }
                },
                additionalProperties: false,
                required: ["title", "pages"],
            }
        }
    },
    required: ["title", "id", "sections"],
    additionalProperties: false,
}

const boilerplate = {
    title: 'Titel der Schulung',
    id: 999,
    sections: [
        {
            title: 'Wie funktioniert pay with dango?',
            pages: [
                {
                    content: [
                        {
                            bold_paragraph: `
                          dango ist eine ganz neue Art zu Bezahlen, die speziell für die Gastronomie entwickelt wurde. 
                          Dango entlastet das Servicepersonal und macht den Bezahlvorgang für alle Beteiligten einfacher, schneller und komfortabler. 
          
                          `,

                        },


                        {
                            image: `${process.env.PUBLIC_URL}/training_assets/qrcodeontable.png`,
                        },

                        { paragraph: "Übrigens: Ihr seid deutschlandweit eines der ersten Restaurants, die diese Lösung anbieten. 😎" }
                    ],
                },
                {
                    content: [
                        {
                            bold_paragraph: `
                          Im folgenden Video siehst du den Bezahlvorgang aus Sicht eines Gastes.
                          `,
                        },
                        { youtube: 'AzMVq1UQceM' },
                        {
                            paragraph: `Als nächstes gehen wir das Ganze mal Schritt für Schritt gemeinsam durch.`,
                        },
                    ],
                },
                {
                    content: [

                        {
                            bold_paragraph: `Wenn der Gast fertig gegessen hat und bezahlen möchte, scannt er 
                    den QR-Code auf seinem Tisch. Jeder Tisch hat seinen eigenen QR-Code, wo auch die 
                    jeweilige Tischnummer kodiert ist.`,
                        },
                        { image: `${process.env.PUBLIC_URL}/training_assets/step1.jpg` },

                        {
                            paragraph: `Damit dango also funktioniert, ist es wichtig, dass ihr 
                  immer auf die richtige Tischnummer bonniert.`},
                        {
                            paragraph: `
                          Der Gast muss normalerweise keine QR-Code Scanner App herunterladen. Einfach nur die 
                          normale Handy-Kamera öffnen und auf den QR-Code richten.
                          `,
                        },
                    ],
                },


                {
                    content: [
                        {
                            bold_paragraph: `So sieht es aus, wenn ein Gast auf "Rechnung aufteilen" klickt.`,
                        },
                        { image: `${process.env.PUBLIC_URL}/training_assets/step6.jpeg` },

                        {
                            paragraph: `Hier kann der Gast nun seine Produkte auswählen. Bereits bezahlte Produkte werden durchgestrichen angezeigt.`,
                        },
                    ],
                },


                {
                    content: [
                        { bold_paragraph: `Stell dir nun folgende Situation vor:` },
                        {
                            paragraph: `Dein Gast scannt den QR-Code um zu zahlen, klickt auf "Jetzt bezahlen" und sieht dann diese Fehlermeldung. `,
                        },
                        {
                            image: `${process.env.PUBLIC_URL}/training_assets/step10.jpeg`,
                        },

                        {
                            question: {
                                title: `Woran kann das liegen?`,
                                type: 'MULTIPLE_CHOICE',
                                choices: [
                                    {
                                        label:
                                            'Mein Gast hat den QR-Code gescannt ohne vorher die dango App zu installieren.',
                                        shouldBe: false,
                                        explanation:
                                            'Es gibt keine dango app. Dango öffnet sich im Browser.',
                                    },
                                    {
                                        label:
                                            'Ich habe die Bestellung des Gastes auf die falsche Tischnummer bonniert.',
                                        shouldBe: true,
                                        explanation:
                                            'Jeder QR-Code ist nämlich an eine Tischnummer gekoppelt.',
                                    },
                                    {
                                        label:
                                            'Der QR-Code gehört zu einem anderen Tisch und wurde versehentlich auf den Tisch meines Gastes gelegt (oder geklebt).',
                                        shouldBe: true,
                                    },
                                    {
                                        label: `Mein Gast hat den QR-Code gescannt und auf "Jetzt bezahlen" geklickt bevor er überhaupt etwas bestellt hat.`,
                                        shouldBe: true,
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        }
    ],

}

const validate = ajv.compile(schema)



function EditorWrapper() {
    const { cid } = useParams()
    let def_val = ""


    if (cid) {
        def_val = localStorage.getItem('traintree') === null ? data.filter(c => c.id === parseInt(cid))[0] : JSON.parse(localStorage.getItem('traintree'))
    } else {
        def_val = localStorage.getItem('traintree') === null ? boilerplate : JSON.parse(localStorage.getItem('traintree'))
    }
    const [cd, set_cd] = useState(def_val)
    const [pre_cd, set_pre_cd] = useState(def_val)
    const save_json = (data) => {
        set_pre_cd(data)
    }

    const import_tree = () => {
        let new_tree = prompt("Füge hier deinen Code ein");

        const isJsonString = (str) => {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        if (new_tree != null && isJsonString(new_tree)) {
            new_tree = JSON.parse(new_tree)
            const valid = validate(new_tree)
            if (!valid) {
                const emessage = validate.errors.map(er => `${er.instancePath}: ${er.message}`).toString()
                alert(`Fehler: ${emessage} \n Fehler korrigieren dann noch Mal neu importieren`)

            } else {
                console.log("new tree is valid")
                localStorage.setItem('traintree', JSON.stringify(new_tree))
                set_pre_cd(new_tree)
                window.location.reload(false);
            }
        } else {
            alert("Code ungültig")
        }
    }

    const deploy = () => {
        const valid = validate(pre_cd)
        if (!valid) {
            const emessage = validate.errors.map(er => `${er.instancePath}: ${er.message}`).toString()
            alert(`Fehler: ${emessage} \n Fehler korrigieren dann noch Mal auf Aktualisieren drücken`)

        } else {
            console.log("is valid")
            localStorage.setItem('traintree', JSON.stringify(pre_cd))
            set_cd(pre_cd)
        }
    }

    const status = validate(pre_cd)

    const reset_all = () => {
        let isExecuted = window.confirm("Sicher dass du alles zurücksetzen willst?");
        if (isExecuted === true) {
            localStorage.clear('traintree')
            window.location.reload(false);

        }
    }

    return (
        <div className='container-fluid p-4 h-100' style={{}}>
            <div className='row h-100'>

                <div className='col-2 h-100'>
                    <div className='d-flex flex-column flex-fill h-100'>
                        <div className='p-2'>
                            <button className='btn btn-primary' onClick={() => { alert("Kopiere den Text aus dem gelben Text-feld und schicke ihn per Email an Omar") }}> Schulung exportieren </button>
                        </div>
                        <div className=' border bg-warning border-2 border-primary w-100 h-100'>
                            <textarea id="exportjson" className='bg-warning form-control h-100 w-100' disabled value={JSON.stringify(cd)} />
                            <button className='btn btn-warning d-block my-2' onClick={import_tree}>Code importieren</button>
                        </div>
                    </div>
                </div>
                <div className='col-6 h-100'>
                    {status === true ? <div className="alert alert-success" role="alert">
                        Tree looks good
                    </div> : <div className="alert alert-danger" role="alert">
                        {validate.errors.map(er => `${er.instancePath}: ${er.message}`).toString()}
                    </div>}
                    <Editor
                        className="h-100"
                        htmlElementProps={{ className: "h-100" }}
                        value={pre_cd}
                        onChange={save_json}

                    />
                    <button className='btn btn-danger m-2' onClick={reset_all}>Mit Muster-Vorlage neustarten</button>
                </div>
                <div className='col-4 h-100'>
                    <div className='d-flex flex-column flex-fill h-100'>
                        <div className='p-2'>
                            <button className='btn btn-primary mr-2' onClick={deploy}> Aktualisieren </button>
                        </div>
                        <div className=' border border-2 border-primary w-100 h-100'>
                            <div style={{ position: "relative" }} className="h-100 w-100">
                                <Course courseId={null} c_data={cd} env="development" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditorWrapper;
import React, { useState, useEffect } from 'react';

const QuestionBlock = ({ question, markSolved }) => {

    const [answers, setAnswers] = useState(
        question.choices.map((a) => ({
            ...a,
            isValue: question.type === 'TRUE_OR_FALSE' ? null : false, // can be true or false
        })),
    );

    const [reveal, setReveal] = useState(false);

    useEffect(() => {
        const newAs = question.choices.map((a) => ({
            ...a,
            isValue: question.type === 'TRUE_OR_FALSE' ? null : false, // can be true or false
        }))

        console.log("effect to update state of qs")
        console.log(newAs)
        setAnswers(newAs)
        setReveal(false)


    }, [question])

    const toggleAnswer = (key) => {
        const newA = JSON.parse(JSON.stringify(answers)).map((a, k) => {
            if (k === key) return { ...a, isValue: !a.isValue };
            return a;
        });
        if (reveal === true) {
            if (newA.every((a) => correct(a))) {
                markSolved();
            }
        }
        setAnswers(newA);
    };

    const toggleAnswerTrue = (key) => {
        const newA = JSON.parse(JSON.stringify(answers)).map((a, k) => {
            if (k === key) return { ...a, isValue: true };
            return a;
        });
        if (reveal === true) {
            if (newA.every((a) => correct(a))) {
                markSolved();
            }
        }
        setAnswers(newA);
    };
    const toggleAnswerFalse = (key) => {
        const newA = JSON.parse(JSON.stringify(answers)).map((a, k) => {
            if (k === key) return { ...a, isValue: false };
            return a;
        });
        if (reveal === true) {
            if (newA.every((a) => correct(a))) {
                markSolved();
            }
        }
        setAnswers(newA);
    };

    useEffect(() => {
        console.log("reveal set to ", reveal)
    }, [reveal])

    const correct = (c) => c.shouldBe === c.isValue;

    const checkAnswers = () => {

        const allAnswersGiven = answers.every(a => a.isValue !== null)

        if (!allAnswersGiven && question.type === "TRUE_OR_FALSE") {
            alert("Du musst alle Fragen beantworten bevor du die Antworten prüfst")
            return 0
        }

        if (reveal === false) { setReveal(true); }

        if (answers.every((a) => correct(a))) {
            markSolved();
        }
    };




    return (
        <div>
            <div className='fs-4 mb-2 mb-2'><b>{question.title}</b></div>
            <ul className="list-group mb-2" key={question.title}>
                {question.type === 'MULTIPLE_CHOICE' && answers.map((c, i) => (
                    <li
                        key={`question_${i}`}
                        className="list-group-item text-start mx-0 p-0">
                        <label
                            className="list-group-item"
                        >
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                checked={answers[i].isValue}
                                onChange={() => {
                                    toggleAnswer(i);
                                }}
                            />
                            {c.label}      {reveal && correct(c) && (
                                <div className="mt-1 alert alert-success" role="alert">
                                    {`✅ Richtig, diese Antwort trifft ${!c.shouldBe ? 'nicht ' : ''
                                        }zu`}
                                </div>
                            )}
                            {reveal && !correct(c) && (
                                <div className="mt-1 alert alert-danger" role="alert">
                                    {`⛔ ${`Diese Antwort wäre ${!c.shouldBe ? 'nicht ' : ''
                                        }richtig gewesen. ${c.explanation || ''}`}`}
                                </div>
                            )}

                        </label>
                    </li>
                ))}

                {question.type === 'TRUE_OR_FALSE' && answers.map((c, i) => (
                    <li
                        key={`question_${i}`}
                        className="list-group-item text-start mx-0 p-0">
                        <span
                            className="list-group-item"
                        >
                            {c.label}
                            <div className="d-block">
                                <button className={`btn w-50 btn-light ${answers[i].isValue === true ? "btn-dark" : ""}`} type="button" onClick={() => { toggleAnswerTrue(i) }}>Korrekt</button>
                                <button className={`btn w-50 btn-light ${answers[i].isValue === false ? "btn-dark" : ""}`} type="button" onClick={() => { toggleAnswerFalse(i) }}>Falsch</button>
                            </div>



                            {reveal && correct(c) && (
                                <div className="mt-1 alert alert-success" role="alert">
                                    {`✅ Richtig, diese Aussage stimmt${!c.shouldBe ? ' nicht' : ''
                                        }.`}
                                </div>
                            )}
                            {reveal && !correct(c) && (
                                <div className="mt-1 alert alert-danger" role="alert">
                                    {`⛔ ${`Diese Aussage stimmt${!c.shouldBe ? ' nicht' : ''
                                        }. ${c.explanation || ''}`}`}
                                </div>
                            )}
                        </span>
                    </li>
                ))}
            </ul>
            <div className="d-grid gap-2 px-3 ">
                <button onClick={checkAnswers} className="btn  btn-dark">
                    Antwort prüfen
                </button>
            </div>
        </div>
    );

};


export default QuestionBlock
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const NextButtonBar = ({
    offerNextPage,
    hasNextPage,
    goToNextPage,
    hasNextSection,
    goToNextSection,
    hasPrevPage,
    goToPrevPage,
    hasPrevSection,
    goToPrevSection,
    env, cid
}) => {
    return (
        <div className="d-grid pb-4 pt-4">
            {!offerNextPage && (
                <>
                    <div className="alert alert-light" role="alert">
                        Du musst alle Fragen korrekt beantworten um weiter zu machen.
                    </div>
                    <button
                        className="btn  btn-lg btn-block btn-primary"
                        onClick={() => { alert("Du musst alle Fragen korrekt beantworten um weiter zu machen.") }}

                    >
                        Weiter <BsArrowRight />
                    </button></>
            )}
            {offerNextPage && hasNextPage && (
                <button
                    className="btn btn-lg btn-block btn-primary"
                    onClick={goToNextPage}>
                    Weiter <BsArrowRight />
                </button>
            )}
            {offerNextPage && !hasNextPage && hasNextSection && (
                <button
                    className="btn btn-lg btn-block btn-primary"
                    onClick={goToNextSection}>
                    Weiter <BsArrowRight />
                </button>
            )}
            {offerNextPage && !hasNextPage && !hasNextSection && (
                // <Link to={env === "production" ? "/train/course_done" : "/train"}>
                <Link to={env === "production" ? `/train/course_done/${cid}` : "/train"}>

                    <button className="btn btn-lg btn-block btn-success">Schulung abschließen!</button>

                </Link>
            )}
            {hasPrevPage &&
                <div className='d-flex justify-items-start mt-4 mb-2'>
                    <button onClick={goToPrevPage} className="btn  btn-light border">Zurück</button>
                </div>}
            {hasPrevSection && !hasPrevPage &&
                <div className='d-flex justify-items-start mt-4 mb-2'>
                    <button onClick={goToPrevSection} className="btn  btn-light border">Zurück</button>
                </div>}
        </div>
    );
};

export default NextButtonBar
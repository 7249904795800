import React from 'react';
import { Link, useParams } from 'react-router-dom';


export default function CourseDone() {
  const { courseId } = useParams()



  return (
    <div
      className="text-white h-100 w-100 p-2 pt-0 pb-2 bg-dark bg-opacity-100 d-flex flex-column justify-content-start align-items-center"
      style={{ width: "100%", }}>
      <div className="mb-4">
        <img alt="Thank you" src={process.env.PUBLIC_URL + '/img/thankyou.gif'} />
      </div>
      <h4>Das war's</h4>
      <h1>Du bist jetzt startklar  👍</h1>
      <hr />
      <div>
        <h5>Hast du noch Fragen oder Feedback?</h5>
        <p>Schreib uns gerne an <a style={{ color: "white" }} href="mailto:hello@paywithdango.com">hello@paywithdango.com</a></p>
      </div>
      <br />
      <Link to={`/train/course/${courseId}`} className='mb-2'>
        <button className="btn btn-light">Neustart</button>
      </Link>
    </div>
  );
}

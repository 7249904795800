import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Training from './components/Training';
import CourseList from './components/CourseList';
import EditorWrapper from './components/EditorWrapper';
import './App.css'; 

function App() {


  useEffect(() => {
    ["/img/thankyou.gif"].forEach((picture) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${picture}`;
    });
  }, [])


   






  return (
    <div className="App h-100"
      style={{ fontFamily: "Helvetica,Arial,'sans-serif'" }}>

      {/* <div className=" container p-2 h-100"> */}

      <Routes key={123}>
        <Route path="/train/*" element={<Training />} />
        <Route path="/index" element={<CourseList />} />


        <Route path="/editor" element={<EditorWrapper />} />
        <Route path="/editor/:cid" element={<EditorWrapper />} />
        <Route
          path="*"
          element={<Navigate to="/train" />}
        />
      </Routes>
    </div>
  );
}



export default App;

